<template>
  <v-toolbar class="merkle-blue-bg" flat>
    <v-toolbar-title>
      <router-link to="/" style="cursor: pointer">
        <img :src="logo" alt="Merkle" class="mt-2" height="30" />
      </router-link>
    </v-toolbar-title>
    <v-divider class="mx-4 grey" vertical />
    <h1>Analytics</h1>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn class="white--text" text v-for="item in menuItems" :key="item.title" :to="item.link">
        {{ item.title }}
      </v-btn>
      <v-btn class="white--text" text @click.stop="$emit('logout')">Logout</v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { EVENT_LOGOUT } from '@/constants';

export default {
  data() {
    return {
      authenticated: false,
      logo: '/img/merkle_logo_white.svg',
      menuItems: [
        {
          title: 'Dashboard',
          link: '/',
        },
        {
          title: 'Profile Search',
          link: '/profileSearch',
        },
      ],
    };
  },
  methods: {
    logout(errorCode) {
      this.$emit(EVENT_LOGOUT, errorCode);
    },
  },
};
</script>

<style>
h1 {
  font-size: 34px;
  white-space: nowrap;
  color: white;
}
img {
  max-width: 100%;
  max-height: 100%;
}
</style>
