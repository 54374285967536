<template>
  <div>
    <profile-search-form
      id="profile-search-form"
      class="mb-5"
      :promotion-hash="promotionHash"
      :search-criteria.sync="criteria"
      @search="newCriteriaSearch()"
    />
    <profile-search-results
      :profiles="profiles"
      :profiles-count="profilesCount"
      :questions="questions"
      :sortOptions.sync="sortOptions"
    />
  </div>
</template>

<script>
import { ALERT_TYPE_ERROR, DEFAULT_PROFILE_SEARCH_CRITERIA, MAX_PAGE_SIZE, SEARCH_DEFAULTS } from '@/constants';
import ClientMixin from '@/mixins/ClientMixin';
import ProfileSearchForm from '@/components/profiles/ProfileSearchForm';
import ProfileSearchResults from '@/components/profiles/ProfileSearchResults';

export default {
  name: 'ProfileSearch',
  mixins: [ClientMixin],
  components: {
    ProfileSearchForm,
    ProfileSearchResults,
  },
  props: ['promotionHash'],

  data() {
    return {
      criteria: DEFAULT_PROFILE_SEARCH_CRITERIA,
      profiles: [],
      profilesCount: 0,
      questions: [],
      sortOptions: {
        page: SEARCH_DEFAULTS.page,
        size: SEARCH_DEFAULTS.size,
      },
    };
  },

  created() {
    this.initComponent();
  },

  watch: {
    promotionHash() {
      this.criteria = DEFAULT_PROFILE_SEARCH_CRITERIA;
      this.sortOptions.page = SEARCH_DEFAULTS.page;
      this.sortOptions.size = SEARCH_DEFAULTS.size;
      this.loadQuestions();
      this.search();
    },

    sortOptions: {
      handler() {
        this.search();
      },
      deep: true,
    },
  },

  methods: {
    initComponent() {
      this.getClient()
        .then((client) => {
          this.client = client;
          this.loadQuestions();
          this.search();
        })
        .catch((error) => {
          this.alertMessage([{ message: error.message, variant: ALERT_TYPE_ERROR }]);
        });
    },

    loadQuestions() {
      const listCriteria = { page: 1, size: MAX_PAGE_SIZE };
      this.client.questions.list(this.promotionHash, listCriteria).then((questions) => {
        this.questions = questions;
      });
    },

    newCriteriaSearch() {
      this.sortOptions.page = 1;
      this.search();
    },

    search() {
      let listCriteria = { ...this.criteria, ...this.sortOptions };
      this.client.profiles.list(this.promotionHash, listCriteria).then((profiles) => {
        this.profiles = profiles;
        this.profilesCount = listCriteria.count;
      });
    },
  },
};
</script>
<style scoped>
#profile-search-form {
  border: solid 1px #bbb;
}
</style>
