<template>
  <v-autocomplete
    id="promotion-select"
    v-model="selectedPromotionHash"
    :items="promotions"
    :loading="isLoading"
    :search-input.sync="search"
    @keydown.enter.native.prevent=""
    autocomplete="off"
    dense
    item-text="displayName"
    item-value="promotionHash"
    label="Select a Promotion"
  ></v-autocomplete>
</template>

<script>
import {
  ALERT_TYPE_ERROR,
  DEBOUNCE_TIMEOUT,
  MAX_PAGE_SIZE,
  MIN_PROMOTION_SEARCH_LENGTH,
  SEARCH_DEFAULTS,
} from '@/constants';
import ClientMixin from '@/mixins/ClientMixin';

export default {
  name: 'PromotionSelect',
  mixins: [ClientMixin],
  props: ['promotionHash'],

  data() {
    return {
      debounceTimeout: DEBOUNCE_TIMEOUT,
      isLoading: false,
      promotions: [],
      search: null,
      searchCriteria: {
        page: SEARCH_DEFAULTS.page,
        size: MAX_PAGE_SIZE,
        order: 'asc',
        displayName: null,
      },
      selectedPromotionHash: null,
    };
  },

  computed: {
    selectedDisplayName() {
      if (this.selectedPromotionHash) {
        const selectedPromotion = this.promotions.find((promotion) => {
          return promotion.promotionHash === this.selectedPromotionHash;
        });
        return selectedPromotion ? selectedPromotion.displayName : null;
      } else {
        return null;
      }
    },
  },

  watch: {
    search(searchText) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        if (searchText && searchText.length >= MIN_PROMOTION_SEARCH_LENGTH && searchText !== this.selectedDisplayName) {
          this.isLoading = true;
          this.searchCriteria['displayName'] = searchText;
          this.client.promotions.list(this.searchCriteria).then((promotions) => {
            this.promotions = promotions;
            this.isLoading = false;
          });
        }
      }, this.debounceTimeout);
    },
    selectedPromotionHash() {
      this.$emit('update:promotionHash', this.selectedPromotionHash);
    },
  },

  created() {
    this.initComponent();
  },

  methods: {
    initComponent() {
      this.getClient()
        .then((client) => {
          this.client = client;
        })
        .catch((error) => {
          this.alertMessage([{ message: error.message, variant: ALERT_TYPE_ERROR }]);
        });
    },
  },
};
</script>
