<template>
  <v-form class="form pa-4" @submit.prevent="search">
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-datetime-picker
              v-model="criteria.registeredAfterInclusive"
              label="Registration Start Date"
              :timePickerProps="{ format: '24hr' }"
              :textFieldProps="{ 'single-line': true }"
            />
          </v-col>
          <v-col>
            <v-datetime-picker
              v-model="criteria.registeredBeforeInclusive"
              label="Registration End Date"
              :timePickerProps="{ format: '24hr' }"
              :textFieldProps="{ 'single-line': true }"
            />
          </v-col>
        </v-row>
        <v-text-field v-model="criteria.lastName" :rules="rules" label="Last Name" />
        <v-text-field v-model="criteria.email" :rules="rules" label="Email" />
        <v-text-field v-model="criteria.age" :rules="rules" label="Age" />
      </v-col>
      <v-col>
        <v-text-field v-model="criteria.city" :rules="rules" label="City" />
        <v-text-field v-model="criteria.stateProvince" :rules="rules" label="State/Province" />
        <v-text-field v-model="criteria.postalCode" :rules="rules" label="Postal Code" />
        <v-text-field v-model="criteria.countryCode" :rules="rules" label="Country Code" />
      </v-col>
    </v-row>
    <v-row justify="end" class="mr-1 mb-1">
      <v-btn @click="search" class="mr-2 merkle-blue-bg white--text" type="submit">Search</v-btn>
      <v-btn @click="showModal = true" class="merkle-blue-bg white--text">Export Results</v-btn>
    </v-row>
    <export-modal :show-modal.sync="showModal" :criteria="criteria" :promotion-hash="promotionHash"></export-modal>
  </v-form>
</template>

<script>
import { EVENT_PSD_SEARCH } from '@/constants';
import ExportModal from '@/components/export/ExportModal';

export default {
  name: 'ProfileSearchForm',
  components: {
    ExportModal,
  },
  props: {
    promotionHash: {
      type: String,
      required: true,
    },
    searchCriteria: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      criteria: { ...this.searchCriteria },
      rules: [],
      showModal: false,
    };
  },

  methods: {
    nulledCriteria() {
      return {
        age: this.replaceEmptyStringWithNull(this.criteria.age),
        city: this.replaceEmptyStringWithNull(this.criteria.city),
        countryCode: this.replaceEmptyStringWithNull(this.criteria.countryCode),
        email: this.replaceEmptyStringWithNull(this.criteria.email),
        lastName: this.replaceEmptyStringWithNull(this.criteria.lastName),
        postalCode: this.replaceEmptyStringWithNull(this.criteria.postalCode),
        registeredAfterInclusive: this.replaceEmptyStringWithNull(this.criteria.registeredAfterInclusive),
        registeredBeforeInclusive: this.replaceEmptyStringWithNull(this.criteria.registeredBeforeInclusive),
        stateProvince: this.replaceEmptyStringWithNull(this.criteria.stateProvince),
      };
    },

    replaceEmptyStringWithNull(value) {
      return value !== '' ? value : null;
    },

    search() {
      this.$emit('update:searchCriteria', this.nulledCriteria());
      this.$emit(EVENT_PSD_SEARCH);
    },
  },
};
</script>

<style scoped></style>
