<template>
  <v-app id="application-container">
    <div v-if="authenticated">
      <navigation-bar @logout="logout" />
    </div>
    <v-container fluid id="content" class="px-5 py-3" :class="[{ 'fill-height': this.$route.name === 'home' }]">
      <alerts @dismissed="onDismissed" />
      <router-view @logout="logout" />
    </v-container>
  </v-app>
</template>

<script>
import { IDLE_TIMEOUT, LOCAL_STORAGE_EXPIRY_KEY, LOCAL_STORAGE_JWT_KEY } from '@/constants';
import { mapActions } from 'vuex';
import Alerts from '@/components/Alerts';
import ClientMixin from '@/mixins/ClientMixin';
import idleTimeout from 'idle-timeout';
import localStorage from './local-storage';
import NavigationBar from '@/components/NavigationBar';

export default {
  components: {
    Alerts,
    NavigationBar,
  },
  mixins: [ClientMixin],
  data() {
    return {
      authenticated: false,
    };
  },
  watch: {
    async $route() {
      this.isAuthenticated();
    },
  },
  methods: {
    ...mapActions(['clearAlert', 'initializeStore']),
    onDismissed(message) {
      this.clearAlert(message);
    },
    isAuthenticated() {
      this.authenticated = !!localStorage.get(LOCAL_STORAGE_JWT_KEY);
    },
    async logout() {
      localStorage.remove(LOCAL_STORAGE_EXPIRY_KEY);
      localStorage.remove(LOCAL_STORAGE_JWT_KEY);
      this.client = null;
      this.initializeStore();
      await this.$router.push({ name: 'login' });
    },
    initComponent() {
      this.isAuthenticated();
      idleTimeout(
        () => {
          this.logout();
        },
        {
          element: document,
          timeout: IDLE_TIMEOUT,
          loop: false,
        },
      );
    },
  },

  created() {
    return this.initComponent();
  },
};
</script>

<style lang="css">
@import './assets/css/main.css';
@import './assets/css/montserrat.css';

#application-container {
  background-color: #fff !important;
  min-height: auto !important;
}

html {
  overflow-y: auto;
}

a:hover {
  text-decoration: none;
}
</style>
