<template>
  <v-card class="mx-auto my-12" max-width="374">
    <v-img :src="logo" class="merkle-blue-bg" alt="Merkle P&LS"></v-img>
    <v-card-title class="justify-center">Forgot Password</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="next" ref="form" v-model="formIsValid">
        <p>A Verification Code will be sent to the email address associated with your username.<br /></p>
        <v-text-field v-model="username" :rules="rules.username" label="User Name"></v-text-field>
        <vue-recaptcha
          class="ml-4 my-2"
          ref="recaptcha"
          :loadRecaptchaScript="true"
          :sitekey="siteKey"
          @verify="verify"
        ></vue-recaptcha>
        <v-btn class="mt-3 white--text merkle-blue-bg" :disabled="!formIsValid || !reCaptchaResponse" type="submit"
          >Next</v-btn
        >
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ALERT_TYPE_ERROR } from '@/constants';
import { mapActions } from 'vuex';
import { required } from '@/validation-rules';
import ClientMixin from '@/mixins/ClientMixin';
import VueRecaptcha from 'vue-recaptcha';
import { Models } from '@helloworld/analytics-client-js';

const VerificationCodeRequest = Models.VerificationCodeRequest;

export default {
  name: 'ForgotPassword',
  mixins: [ClientMixin],
  components: {
    VueRecaptcha,
  },
  props: {
    error: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    formIsValid: false,
    logo: '/img/company-logo-transparent.svg',
    reCaptchaResponse: null,
    rules: {
      username: [required],
    },
    siteKey: '6LdCGe8aAAAAAEGys7_tfeHF9cZJeqZq-LWVuaAt',
    username: '',
  }),

  mounted() {
    this.initComponent();
  },

  methods: {
    ...mapActions(['alertMessage']),

    async initComponent() {
      this.client = await this.getClient();
    },

    async next() {
      const request = new VerificationCodeRequest(this.username, this.reCaptchaResponse);

      try {
        await this.client.passwordReset.requestVerificationCode(request);
        await this.$router.push({ name: 'reset-password' });
      } catch (error) {
        this.reCaptchaResponse = null;
        //noinspection JSUnresolvedFunction
        this.$refs.recaptcha.reset();
        this.alertMessage([{ message: error.message, variant: ALERT_TYPE_ERROR }]);
      }
    },

    verify(response) {
      this.reCaptchaResponse = response;
    },
  },
};
</script>
