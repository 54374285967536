import { LOCAL_STORAGE_JWT_KEY } from '@/constants';
import localStorage from '@/local-storage';
import routes from '@/router/routes';
import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
});

const onAuthRequired = (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && !localStorage.get(LOCAL_STORAGE_JWT_KEY)) {
    next({ path: '/login' });
  } else {
    next();
  }
};

router.beforeEach((to, from, next) => {
  // noinspection JSIgnoredPromiseFromCall
  store.dispatch('initAlerts');
  onAuthRequired(to, from, next);
});

export default router;
