<template>
  <div :id="reportContainerId"></div>
</template>

<script>
import * as pbi from 'powerbi-client';
import { clearIntervalAsync } from 'set-interval-async';
import jwt_decode from 'jwt-decode';
import localStorage from '../local-storage';
import {
  ACCOUNT_FILTER,
  LOCAL_STORAGE_EXPIRY_KEY,
  LOCAL_STORAGE_JWT_KEY,
  PROMOTION_HASHES_CLAIM,
  TEN_MIN_IN_MILLISECONDS,
  USER_PROGRAM_IS_ADMIN_SUFFIX,
} from '@/constants';
import { setIntervalAsync } from 'set-interval-async/fixed';
import ClientMixin from '@/mixins/ClientMixin';

export default {
  name: 'Home',
  mixins: [ClientMixin],

  data() {
    return {
      reportContainerId: 'reportContainer',
      timer: null,
      tokenExpiration: null,
    };
  },

  async destroyed() {
    if (this.timer !== null) {
      await clearIntervalAsync(this.timer);
    }
  },

  mounted() {
    this.initComponent();
  },

  methods: {
    getAccountFilter(jwt) {
      const filter = { ...ACCOUNT_FILTER };
      filter.values = this.parsePromotionHashesFromJwt(jwt);
      return filter;
    },

    getEmbedConfig(embedToken) {
      const jwt = localStorage.get(LOCAL_STORAGE_JWT_KEY);

      return {
        accessToken: embedToken.token,
        embedUrl: 'https://app.powerbi.com/reportEmbed',
        filters: [this.getAccountFilter(jwt)],
        id: embedToken.reportId,
        permissions: pbi.models.Permissions.Read,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
        },
        tokenType: pbi.models.TokenType.Embed,
        type: 'report',
      };
    },

    async getNewEmbedAndAccessToken() {
      await this.getNewEmbedToken();
      const { accessToken, expiresIn } = await this.client.auth.refreshToken();
      localStorage.set(LOCAL_STORAGE_JWT_KEY, accessToken);
      localStorage.set(LOCAL_STORAGE_EXPIRY_KEY, expiresIn * 1000 - TEN_MIN_IN_MILLISECONDS);
    },

    async getNewEmbedToken() {
      const embedToken = await this.client.powerBi.getEmbedToken();
      const config = this.getEmbedConfig(embedToken);
      window.powerbi.embed(document.getElementById(this.reportContainerId), config);
    },

    async initComponent() {
      this.client = await this.getClient();
      this.tokenExpiration = parseInt(localStorage.get(LOCAL_STORAGE_EXPIRY_KEY));

      await this.getNewEmbedToken();
      this.timer = setIntervalAsync(async () => {
        await this.getNewEmbedAndAccessToken();
      }, this.tokenExpiration);
    },

    parsePromotionHashesFromJwt(jwt) {
      const decodedJwt = jwt_decode(jwt);
      if (PROMOTION_HASHES_CLAIM in decodedJwt) {
        return decodedJwt[PROMOTION_HASHES_CLAIM].map((claim) => claim.replace(USER_PROGRAM_IS_ADMIN_SUFFIX, ''));
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
#reportContainer {
  width: 100%;
  height: 100%;
  border: 0;
}
#reportContainer iframe {
  border: 0;
}
</style>
