import ForgotPassword from '@/components/password/ForgotPassword';
import Home from '@/components/Home';
import Login from '@/components/Login';
import ProfileSearchAndDownload from '@/components/ProfileSearchAndDownload';
import ResetPassword from '@/components/password/ResetPassword';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/forgotpassword',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/profileSearch',
    name: 'profile-search',
    component: ProfileSearchAndDownload,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/resetpassword',
    name: 'reset-password',
    component: ResetPassword,
    props: (route) => ({ verificationCode: route.query.verificationCode }),
    meta: {
      requiresAuth: false,
    },
  },
];
