<template>
  <div>
    <div v-for="alert in getAlerts" :key="alert.message">
      <v-alert
        :value="true"
        :type="alert.variant"
        class="alert text-center ma-4"
        dismissible
        @input="onClose(alert.message)"
      >
        <div v-html="alert.message"></div>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { EVENT_DISMISSED } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'Alerts',
  computed: {
    ...mapGetters(['getAlerts']),
  },
  methods: {
    onClose(message) {
      this.$emit(EVENT_DISMISSED, message);
    },
  },
};
</script>
