<template>
  <div class="pt-4">
    <v-row>
      <v-col cols="2" class="pt-5">
        <promotion-select :promotion-hash.sync="promotionHash"></promotion-select>
      </v-col>
      <v-col cols="10">
        <profile-search v-if="promotionHash != null" :promotion-hash="promotionHash"></profile-search>
        <div v-if="promotionHash == null">
          <p class="text-center pt-10">Please select a Promotion from the drop-down to the left.</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientMixin from '@/mixins/ClientMixin';
import ProfileSearch from '@/components/profiles/ProfileSearch';
import PromotionSelect from '@/components/promotions/PromotionSelect';

export default {
  name: 'ProfileSearchAndDownload',
  components: { PromotionSelect, ProfileSearch },
  mixins: [ClientMixin],

  data() {
    return {
      promotionHash: null,
    };
  },

  mounted() {
    this.initComponent();
  },

  methods: {
    async initComponent() {
      this.client = await this.getClient();
    },
  },
};
</script>

<style></style>
