export default {
  ALERTS(state, alert) {
    state.alerts = alert;
  },
  FLASHES(state, { message, variant = 'warning' }) {
    // Multiple asynchronous calls may generate the same flash message.
    // This check removes a console warning about duplicate keys in the array.
    // Only push the flash in if it doesn't already exist.
    if (state.flashes.findIndex((flash) => flash.message === message) === -1) {
      state.flashes.push({
        message,
        variant,
      });
    }
  },
  INIT_ALERTS(state) {
    state.alerts = [].slice.call(state.flashes);
    state.flashes = [];
  },
  INITIALIZE_STORE(state) {
    state.alerts = [];
    state.flashes = [];
  },
};
