<template>
  <v-card class="mx-auto my-12" max-width="374">
    <v-img :src="logo" class="merkle-blue-bg" alt="Merkle P&LS"></v-img>
    <v-card-title class="justify-center">Update Password</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="submit" ref="form" v-model="formIsValid">
        <v-text-field
          v-model="username"
          :rules="rules.username"
          label="User Name"
          autofill="off"
          autocomplete="off"
        ></v-text-field>
        <v-text-field
          v-model="enteredVerificationCode"
          :rules="rules.enteredVerificationCode"
          label="Verification Code"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="rules.password"
          label="Password"
          :type="visibility"
          ref="password"
          validate-on-blur
          autofill="off"
          autocomplete="new-password"
          @input="$refs.confirmedPassword.resetValidation()"
        >
        </v-text-field>
        <v-text-field
          v-model="confirmedPassword"
          :rules="rules.confirmedPassword"
          label="Repeat Password"
          :type="visibility"
          append-icon="mdi-eye-off-outline"
          @click:append="toggleVisibility()"
          ref="confirmedPassword"
          autofill="off"
          autocomplete="new-password"
          @input="$refs.password.resetValidation()"
        >
        </v-text-field>
        <v-btn class="mt-3 merkle-blue-bg white--text" :disabled="!formIsValid" type="submit">Submit</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { isValidLdapPassword, required } from '@/validation-rules';
import { ALERT_TYPE_ERROR } from '@/constants';
import ClientMixin from '@/mixins/ClientMixin';
import { Models } from '@helloworld/analytics-client-js';

const UpdatePasswordRequest = Models.UpdatePasswordRequest;

export default {
  name: 'ResetPassword',
  mixins: [ClientMixin],
  props: {
    verificationCode: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      confirmedPassword: '',
      enteredVerificationCode: this.verificationCode ? this.verificationCode : '',
      formIsValid: true,
      logo: '/img/company-logo-transparent.svg',
      password: '',
      rules: {
        username: [required],
        enteredVerificationCode: [required],
        password: [isValidLdapPassword, required, this.passwordConfirmationRule],
        confirmedPassword: [required, this.passwordConfirmationRule],
      },
      username: '',
      visibility: 'password',
    };
  },

  mounted() {
    this.initComponent();
  },

  methods: {
    ...mapActions(['alertMessage']),

    async initComponent() {
      this.client = await this.getClient();
    },

    passwordConfirmationRule() {
      return this.password === this.confirmedPassword || 'Passwords must match';
    },

    async submit() {
      const request = new UpdatePasswordRequest(this.username, this.password, this.enteredVerificationCode);

      try {
        await this.client.passwordReset.updatePassword(request);
        await this.$router.push({ name: 'login' });
      } catch (error) {
        this.alertMessage([{ message: error.message, variant: ALERT_TYPE_ERROR }]);
      }
    },

    toggleVisibility() {
      this.visibility = this.visibility === 'password' ? 'text' : 'password';
    },
  },
};
</script>
