import App from '@/App';
import DatetimePicker from 'vuetify-datetime-picker';
import router from '@/router';
import store from '@/store/index';
import Vue from 'vue';
import vuetify from './plugins/vuetify';

Vue.use(DatetimePicker);

Vue.config.productionTip = false;
/* eslint-disable no-new */
store.dispatch('initializeStore').then(() => {
  new Vue({
    store,
    el: '#app',
    router,
    vuetify,
    render: (h) => h(App),
  });
});
