import {
  INVALID_EXPORT_PASSWORD_LENGTH,
  INVALID_LDAP_PASSWORD_LENGTH,
  MIN_EXPORT_PASSWORD_LENGTH,
  MIN_LDAP_PASSWORD_LENGTH,
} from '@/constants';

export const REQUIRED_FIELD = 'Field is required';

function required(value) {
  return (value !== null && value !== '') || REQUIRED_FIELD;
}
function isValidLdapPassword(password) {
  if (password !== null && password !== undefined) {
    return password.trim().length >= MIN_LDAP_PASSWORD_LENGTH || INVALID_LDAP_PASSWORD_LENGTH;
  }
  return false;
}

function isValidExportPassword(password) {
  if (password !== null && password !== undefined) {
    return password.trim().length >= MIN_EXPORT_PASSWORD_LENGTH || INVALID_EXPORT_PASSWORD_LENGTH;
  }
  return false;
}

export { required, isValidExportPassword, isValidLdapPassword };
