export const ERROR_CODE_INVALID_TOKEN = 'E0001';
export const INVALID_TOKEN = 'Unable to get a valid access token, please try logging in again.';

export const IDLE_TIMEOUT = 900000; // 1000 * 60 * 15 == 15 minutes

export const TEN_MIN_IN_MILLISECONDS = 600000;

export const EVENT_DISMISSED = 'dismissed';
export const EVENT_LOGOUT = 'logout';

export const ALERT_TYPE_ERROR = 'error';
export const ALERT_TYPE_SUCCESS = 'success';

export const LOCAL_STORAGE_JWT_KEY = 'jwt';
export const LOCAL_STORAGE_EXPIRY_KEY = 'expiresIn';

export const MIN_LDAP_PASSWORD_LENGTH = 8;
export const MIN_EXPORT_PASSWORD_LENGTH = 16;
export const INVALID_LDAP_PASSWORD_LENGTH = `Please enter a password that is at least ${MIN_LDAP_PASSWORD_LENGTH} characters.`;
export const INVALID_EXPORT_PASSWORD_LENGTH = `Please enter a password that is at least ${MIN_EXPORT_PASSWORD_LENGTH} characters.`;

export const ACCOUNT_FILTER = {
  $schema: 'https://powerbi.com/product/schema#basic',
  target: {
    table: 'd_promotion_bi',
    column: 'promotion_hash',
  },
  operator: 'In',
  values: null,
  requireSingleSelection: false,
};

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';

export const MIN_PROMOTION_SEARCH_LENGTH = 3;
export const MAX_PAGE_SIZE = 1000;

export const SEARCH_DEFAULTS = {
  page: 1,
  size: 10,
  sortColumn: 'id',
  order: SORT_ASC,
};

export const DEFAULT_PROFILE_SEARCH_CRITERIA = {
  age: null,
  city: null,
  countryCode: null,
  email: null,
  lastName: null,
  postalCode: null,
  registeredAfterInclusive: null,
  registeredBeforeInclusive: null,
  stateProvince: null,
};

export const PROMOTION_HASHES_CLAIM = 'promotionHashes';
export const DEBOUNCE_TIMEOUT = 250;
export const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
export const EVENT_PSD_SEARCH = 'search';
export const NO_ANSWER_PLACEHOLDER = '-';
export const USER_PROGRAM_IS_ADMIN_SUFFIX = '_is_admin';

export const PROFILE_EXPORT_REQUEST_SUBMITTED =
  'Your Profile Export request has been submitted. You will receive an email when it completes';
