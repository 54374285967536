<template>
  <v-card class="mx-auto my-12" max-width="374">
    <v-img :src="logo" alt="Merkle P&LS" width="374" height="157" class="merkle-blue-bg"></v-img>
    <v-card-title class="justify-center">Merkle P&amp;LS Analytics</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="login" ref="form" v-model="formIsValid">
        <v-text-field
          v-model="username"
          :rules="rules.username"
          label="User Name"
          autofill="off"
          autocomplete="new-password"
        >
        </v-text-field>
        <v-text-field
          v-model="password"
          :rules="rules.password"
          label="Password"
          type="password"
          autofill="off"
          autocomplete="new-password"
        ></v-text-field>
        <v-row class="align-center mt-1" dense>
          <v-col
            ><v-btn :disabled="!formIsValid" class="white--text merkle-blue-bg" type="submit">Sign In</v-btn></v-col
          >
          <v-col>
            <v-btn class="pr-1" :to="{ name: 'forgot-password' }" :ripple="false" plain> Forgot Password? </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  ALERT_TYPE_ERROR,
  LOCAL_STORAGE_EXPIRY_KEY,
  LOCAL_STORAGE_JWT_KEY,
  TEN_MIN_IN_MILLISECONDS,
} from '@/constants';
import { mapActions } from 'vuex';
import { required } from '@/validation-rules';
import ClientMixin from '@/mixins/ClientMixin';
import { Models } from '@helloworld/analytics-client-js';
import localStorage from '../local-storage';

const Credentials = Models.Credentials;

export default {
  name: 'Login',
  mixins: [ClientMixin],
  props: {
    error: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    logo: '/img/company-logo-transparent.svg',
    username: '',
    password: '',
    rules: {
      username: [required],
      password: [required],
    },
    formIsValid: true,
  }),

  mounted() {
    this.initComponent();
  },

  methods: {
    ...mapActions(['alertMessage']),

    async initComponent() {
      this.client = await this.getClient();
    },

    async login() {
      const credentials = new Credentials(this.username, this.password);

      try {
        const { accessToken, expiresIn } = await this.client.auth.authenticate(credentials);
        localStorage.set(LOCAL_STORAGE_JWT_KEY, accessToken);
        localStorage.set(LOCAL_STORAGE_EXPIRY_KEY, expiresIn * 1000 - TEN_MIN_IN_MILLISECONDS);
        await this.$router.push({ name: 'home' });
      } catch (error) {
        this.alertMessage([{ message: error.message, variant: ALERT_TYPE_ERROR }]);
      }
    },
  },
};
</script>
