import { ALERTS, FLASHES, INIT_ALERTS, INITIALIZE_STORE } from '@/store/types';
import { ALERT_TYPE_ERROR, LOCAL_STORAGE_JWT_KEY, LOCAL_STORAGE_EXPIRY_KEY } from '@/constants';
import axios from 'axios';

import localStorage from '../local-storage';

function handleRequestError(store) {
  return (error) => {
    const { response = {} } = error;
    const { status } = response;

    switch (status) {
      case 401:
        // The user's token is no longer valid
        store.dispatch('logout');
        break;

      case 504:
        // The back-end service is not responding
        store.dispatch('alertMessage', [
          {
            message: 'Unable to connect to Analytics Service.',
            variant: ALERT_TYPE_ERROR,
          },
        ]);
        break;

      default:
        // All other statuses must be handled by the calling code.
        break;
    }

    throw error.response;
  };
}

export default {
  alertMessage({ commit }, alerts) {
    commit(ALERTS, alerts);
  },
  clearAlert({ commit, getters }, alertMessage) {
    const { getAlerts } = getters;
    const alertArrCopy = getAlerts.slice(0);
    const indexOfFoundMessage = getAlerts.findIndex((alert) => alert.message === alertMessage);
    if (indexOfFoundMessage !== -1) {
      alertArrCopy.splice(indexOfFoundMessage, 1);
    }
    commit(ALERTS, alertArrCopy);
  },
  flashMessage({ commit }, alert) {
    commit(FLASHES, alert);
  },
  initAlerts({ commit }) {
    commit(INIT_ALERTS);
  },
  initializeStore({ commit }) {
    commit(INITIALIZE_STORE);
  },
  logout() {
    localStorage.remove(LOCAL_STORAGE_EXPIRY_KEY);
    localStorage.remove(LOCAL_STORAGE_JWT_KEY);
  },
  readApplicationVersion(store) {
    return axios
      .get('/ping')
      .then((response) => response.headers['x-version'])
      .catch(handleRequestError(store));
  },
};
