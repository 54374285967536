// eslint-disable-next-line no-unused-vars
import { LOCAL_STORAGE_JWT_KEY } from '@/constants';
import { AnalyticsClient } from '@helloworld/analytics-client-js';
import localStorage from '../local-storage';

export default {
  data() {
    return {
      client: null,
    };
  },
  methods: {
    async getClient() {
      if (!(this.client instanceof AnalyticsClient)) {
        await this.initClient();
      }
      return this.client;
    },

    async initClient() {
      const token = localStorage.get(LOCAL_STORAGE_JWT_KEY);
      this.client = new AnalyticsClient(token);
    },
  },
};
