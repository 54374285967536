<template>
  <div id="profileSearchResults">
    <v-data-table
      :items="profiles"
      :no-data-text="emptyText"
      :headers="headers"
      :options.sync="options"
      :footer-props="footerProps"
      :server-items-length="profilesCount"
    >
      <template v-for="question in questions" v-slot:[`item.${question.questionId}`]="{ item }">
        {{ getProfileQuestionAnswer(question.questionId, item) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ITEMS_PER_PAGE_OPTIONS, NO_ANSWER_PLACEHOLDER } from '@/constants';
import ClientMixin from '@/mixins/ClientMixin';

const baseHeaders = [
  { text: 'Profile Key', value: 'profileKey', align: 'center', sortable: false },
  { text: 'Profile ID', value: 'profileId', align: 'center', sortable: false },
  { text: 'First Name', value: 'firstName', align: 'center', sortable: false },
  { text: 'Last Name', value: 'lastName', align: 'center', sortable: false },
  { text: 'Email', value: 'email', align: 'center', sortable: false, width: '300px' },
  { text: 'Gender', value: 'gender', align: 'center', sortable: false },
  { text: 'Age', value: 'age', align: 'center', sortable: false },
  { text: 'Eligibility Status', value: 'eligibilityStatus', align: 'center', sortable: false },
  { text: 'Address 1', value: 'address1', align: 'center', sortable: false, width: '250px' },
  { text: 'Address 2', value: 'address2', align: 'center', sortable: false, width: '250px' },
  { text: 'City', value: 'city', align: 'center', sortable: false },
  { text: 'State / Province', value: 'stateProvince', align: 'center', sortable: false },
  { text: 'Postal Code', value: 'postalCode', align: 'center', sortable: false },
  { text: 'Country Code', value: 'countryCode', align: 'center', sortable: false },
  { text: '# Plays', value: 'numPlays', align: 'center', sortable: false },
  { text: '# Sweeps', value: 'numSweeps', align: 'center', sortable: false },
  { text: 'Registered At', value: 'registeredAt', align: 'center', sortable: false, width: '200px' },
];

export default {
  name: 'ProfileSearchResults',
  mixins: [ClientMixin],
  props: {
    profiles: {
      type: Array,
      required: true,
    },
    profilesCount: {
      type: Number,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    sortOptions: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      emptyText: 'No items found',
      footerProps: {
        'items-per-page-options': ITEMS_PER_PAGE_OPTIONS,
      },
      headers: baseHeaders,
      options: {
        itemsPerPage: this.sortOptions.size,
        page: this.sortOptions.page,
      },
      title: 'Search Results',
    };
  },

  watch: {
    options: {
      handler() {
        this.$emit('update:sortOptions', { page: this.options.page, size: this.options.itemsPerPage });
      },
      deep: true,
    },

    questions() {
      this.addQuestionNamesToTableHeaders();
    },

    sortOptions: {
      handler() {
        this.options.itemsPerPage = this.sortOptions.size;
        this.options.page = this.sortOptions.page;
      },
      deep: true,
    },
  },

  methods: {
    addQuestionNamesToTableHeaders() {
      this.headers = [...baseHeaders];
      this.questions.forEach((question) =>
        this.headers.push({
          text: question.questionName,
          value: question.questionId,
          align: 'center',
          sortable: false,
        }),
      );
    },

    getProfileQuestionAnswer(questionId, item) {
      const answeredQuestion = item.questionAnswers.find((questionAnswer) => {
        return questionAnswer.questionId === questionId;
      });
      return answeredQuestion ? answeredQuestion.answer : NO_ANSWER_PLACEHOLDER;
    },
  },
};
</script>
