/**
 * Actual localStorage provides simple get()/set()/remove() methods to set key-value pairs. This
 * collection of functions aims to provide a bit more functionality around manipulating the data
 * stored in localStorage.
 */
export default {
  get(key) {
    return localStorage.getItem(key) || null;
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  set(key, jwt) {
    localStorage.setItem(key, jwt);
  },
};
